import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchRegisteredExams } from "../../features/user/thunks";
import { ExamInfo } from "../../utils/common-types";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { startExam } from "../../features/exam/reducers";

type ExamPanelItemProps = {
	examInfo: ExamInfo;
};

const ExamPanelItem = ({ examInfo }: ExamPanelItemProps) => {

	const unixTimestampToString = (ts: number) => {
		let date = new Intl.DateTimeFormat('en-LK', { dateStyle: 'full', timeStyle: 'short', timeZone: 'Asia/Colombo' }).format(ts * 1000);
		return date.toString();
	};

	return (
		<div className="exam-panel-item">
			<div className="item-data">
				<Typography variant="h6" className="exam-name">{examInfo.examName}</Typography>
				<div className="exam-start">Starts at {unixTimestampToString(examInfo.examStartTime)}. Duration {(examInfo.examDuration / 60)} minutes</div>
			</div>
			<Button className="action-button" variant="outlined" onClick={() => {
				console.log("dispatching event for exam " + examInfo);
				window.dispatchEvent(new CustomEvent<ExamInfo>('startExam', { detail: examInfo, bubbles: true }));
			}}>Start</Button>
		</div>
	);
};

type StartExamDialogProps = {
	examInfo?: ExamInfo;
	open: boolean;
	setOpen: (open: boolean) => void;
};

const StartExamDialog = ({ examInfo, open, setOpen }: StartExamDialogProps) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	return (
		<Dialog open={open}
			onClose={() => { setOpen(false); }}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title">
				{"Start exam?"}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Are you sure you want to start the exam {examInfo?.examName}?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => { setOpen(false); }}>No</Button>
				<Button onClick={
					() => {
						dispatch(startExam(examInfo?.examId));
						setOpen(false);
						navigate("/exam");
					}
				} autoFocus>
					Yes
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export const ExamsPanel = () => {
	const { userId } = useAppSelector(state => state.auth);
	const { studentUser } = useAppSelector(state => state.user);

	const dispatch = useAppDispatch();

	const handleStartExam = (e: CustomEvent<ExamInfo>) => {
		console.log('confimation');
		setStartExamDialogOpen(true);
		setStartExamDialogExamInfo(e.detail);
	};

	useEffect(() => {
		const handler = (e: Event) => {
			console.log('caught event ' + e);
			handleStartExam(e as CustomEvent<ExamInfo>);
		};

		console.log('registering startExam handler');
		window.addEventListener('startExam', handler);


		return () => {
			console.log('removing startExam handler');
			window.removeEventListener('startExam', handler);
		};
	});

	useEffect(() => {
		if (userId && !studentUser?.registeredExams) {
			dispatch(fetchRegisteredExams(userId));
		}
	});

	const registeredExams = studentUser?.registeredExams ?? [];
	const examList = registeredExams.map(
		(examInfo) =>
			<ExamPanelItem key={examInfo.examId} examInfo={examInfo} />
	);

	const [startExamDialogOpen, setStartExamDialogOpen] = useState(false);
	const [startExamDialogExamInfo, setStartExamDialogExamInfo] = useState<ExamInfo>();

	return (
		<div className="exams-panel">
			<Typography variant="h4">Available Exams</Typography>
			{examList}
			<StartExamDialog open={startExamDialogOpen} setOpen={setStartExamDialogOpen} examInfo={startExamDialogExamInfo} />
		</div>
	);
};
