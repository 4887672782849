import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { store } from './store';
import Home from '../pages/home';
import Login from '../pages/login';
import ExamPage from '../pages/exam';

function App() {
  return (
    <div className='App'>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path='home/*' element={<Home />} />
            <Route path='exam' element={<ExamPage />} />
            <Route path='login' element={<Login />} />
            <Route path='*' element={<Navigate to='/login' />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
