import { unwrapResult } from "@reduxjs/toolkit";
import React, { KeyboardEvent, useEffect, useState } from "react";
import { Navigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { performLogin } from "../features/auth/thunks";
import { Button, TextField, Typography } from '@mui/material';
import './login.css';
import { LoginResult } from "../features/auth/types";

const LoginHeader = () => {
	return (
		<div className="login-header">
			<div>
				<Typography variant="h6" className="login-form-title">
					SLOMF Online Examinations
				</Typography>
			</div>
			<div className="logo-container">
				<img src="/logo192.png" alt="SLOMF LOGO" />
			</div>
		</div>
	);
};

export const Login = () => {
	const userIdRef = React.createRef<HTMLInputElement>();
	const passwordRef = React.createRef<HTMLInputElement>();


	const { status } = useAppSelector(state => state.auth);
	const dispatch = useAppDispatch();

	const [formUserId, setFormUserId] = useState<string>('');
	const [errorMsg, setErrorMsg] = useState<string | null>();

	useEffect(() => {
		if (status === 'PENDING') {
			dispatch(performLogin());
		}
	}, [dispatch, status]);


	const handleLogin = () => {
		if (userIdRef.current!.value.trim() === '') {
			setErrorMsg('The username cannot be empty.');
			return;
		}
		if (passwordRef.current!.value === '') {
			setErrorMsg('The password cannot be empty.');
			return;
		}
		console.log('Attempting to log in as ' + userIdRef.current!.value);
		setFormUserId(userIdRef.current!.value);
		const loginAction = performLogin(
			{
				userId: userIdRef.current!.value,
				password: passwordRef.current!.value,
			}
		);
		dispatch(loginAction)
			.then(unwrapResult).then(
				(response: LoginResult) => {
					if (!response.userId) {
						throw Error('Login failed.');
					}
					console.log('login succeeded');
				})
			.catch(
				(error) => {
					console.log(error);
					console.log('Login failed for userId: ' + userIdRef.current?.value + '.');
					setErrorMsg('Login failed. Please try again.');
				});
	};

	if (status === 'PENDING') {
		return (
			<div>
				Attempting to log in...
			</div>
		);
	}

	if (status === 'LOGGED_IN') {
		return (
			<Navigate to='/home' />
		);
	}

	return (
		<div className="login-page">
			<div className="login-container">
				<LoginHeader />
				<Typography variant="h6" className="login-form-instructions">Please log in to continue.</Typography>
				<form className="login-form" onSubmit={(e) => { handleLogin(); e.preventDefault(); }}>
					<TextField label="User Id" aria-invalid inputRef={userIdRef} defaultValue={formUserId} onKeyUp={
						(e: KeyboardEvent<HTMLDivElement>) => {
							if (e.key === 'Enter') {
								passwordRef.current!.focus();
							}
						}} />
					<TextField label="Password" inputRef={passwordRef} type="password" onKeyUp={
						(e: KeyboardEvent<HTMLDivElement>) => {
							if (e.key === 'Enter') {
								handleLogin();
							}
						}} />
					<Button variant="contained" onClick={handleLogin}>Login</Button>
				</form>
				<Typography className="form-error-msg">
					{errorMsg}
				</Typography>
			</div>
		</div>
	);
};

export default Login;