import React from "react";
import { UserInfoPanel } from "./user-info-panel";
import { ExamsPanel } from "./exams-panel";
import './student.css';

export const StudentHome = () => {
	return (
		<div className="student-home-container">
			<UserInfoPanel />
			<ExamsPanel />
		</div>
	);
};

export default StudentHome;
