import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from 'axios';

import { backendAxios } from '../../utils/api';

interface UserDataResponse { }

export const fetchExam = createAsyncThunk(
	'user/userdata',
	async (examId: string): Promise<unknown> => {
		const response: AxiosResponse<UserDataResponse> = await backendAxios.post<UserDataResponse>(
			'api/exams/exam', { examId }
		);
		return {};
	}
);

export const postResponse = createAsyncThunk(
	'user/userdata',
	async (examId: string): Promise<unknown> => {
		const response: AxiosResponse<UserDataResponse> = await backendAxios.post<UserDataResponse>(
			'api/exams/response', { examId }
		);
		return {};
	}
);

export const finishExam = createAsyncThunk(
	'user/userdata',
	async (examId: string): Promise<unknown> => {
		const response: AxiosResponse<UserDataResponse> = await backendAxios.post<UserDataResponse>(
			'api/exams/finish', { examId }
		);
		return {};
	}
);