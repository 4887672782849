import { createSlice } from "@reduxjs/toolkit";
import { UserState } from "./types";
import { fetchRegisteredExams, fetchUserData } from "./thunks";

const initialState = {} as UserState;

const slice = createSlice({
	name: 'user',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchUserData.fulfilled, (state, action) => {
			if (!state.studentUser) {
				state.studentUser = {};
			}
			const studentUser = state.studentUser;
			studentUser.userData = action.payload;
		});

		builder.addCase(fetchRegisteredExams.fulfilled, (state, action) => {
			if (!state.studentUser) {
				state.studentUser = {};
			}
			const studentUser = state.studentUser;
			studentUser.registeredExams = action.payload;
		});
	},
});

export default slice.reducer;
