import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from 'axios';

import { backendAxios } from '../../utils/api';
import { UserData } from "./types";
import { ExamInfo } from "../../utils/common-types";
import { wait } from "@testing-library/user-event/dist/utils";

interface UserDataResponse { }

export const fetchUserData = createAsyncThunk(
	'user/userdata',
	async (userId: string): Promise<UserData> => {
		// const response: AxiosResponse<UserDataResponse> = await backendAxios.post<UserDataResponse>(
		// 	'api/user/userdata', { userId }
		// );
		//TODO: return actual data
		return {
			userId: 'stu1',
			prefferedName: 'John',
			fullName: 'John Smith',
			dateOfBirth: '2004-05-31',
			photoIdUrl: 'unknown',
			photoUrl: 'unknown',
			contactNumbers: ['0718293465', '0732328402'],
			personalAddress: 'Number 1234, Street Pi, Eularia',
			school: 'Mathematics for Gifted Students',
			schoolAddress: 'Some street, Some city, Some country'
		};
	}
);

interface RegisterdExamsResponse { }

export const fetchRegisteredExams = createAsyncThunk(
	'user/registered_exams',
	async (userId: string): Promise<ExamInfo[]> => {
		// const response: AxiosResponse<RegisterdExamsResponse> = await backendAxios.post<RegisterdExamsResponse>(
		// 	'api/user/userdata', { userId }
		// );
		//TODO: return actual data
		wait(1);
		return [
			{ examId: 'exam1', examName: 'Exam Name 1', medium: 'EN', examStartTime: 1678016413, examDuration: 3600 },
			{ examId: 'exam2', examName: 'Exam Name 2', medium: 'EN', examStartTime: 1678016413, examDuration: 3600 },
			{ examId: 'exam3', examName: 'Exam Name 3', medium: 'EN', examStartTime: 1678016413, examDuration: 3600 },
			{ examId: 'exam4', examName: 'Exam Name 4', medium: 'EN', examStartTime: 1678016413, examDuration: 3600 },
		];
	}
)

