import React, { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import { performLogin } from "../features/auth/thunks";
import "./home.css";

export const ExamPage = () => {
	const { status, userId } = useAppSelector(state => state.auth);

	const dispatch = useAppDispatch();
	useEffect(() => {
		if (status === 'PENDING') {
			dispatch(performLogin());
		}
	}, [status, dispatch]);

	if (status === 'PENDING') {
		return (
			<div>
				Checking if the user is logged in...
			</div>
		);
	}
	return (
		<div className="exam-page">
			Hello {userId}. Here, you will see your selected exam (if selected). Otherwise, you will be redirected back to home page.
		</div>
	);
};

export default ExamPage;