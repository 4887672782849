import { configureStore } from '@reduxjs/toolkit';

import authReducer from '../features/auth/reducers';
import userReducer from '../features/user/reducers';
import examReducer from '../features/exam/reducers';

export const store = configureStore({
	reducer: {
		auth: authReducer,
		user: userReducer,
		exam: examReducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		immutableCheck: {
			ignoredPaths: ['user']
		},
		serializableCheck: { ignoredPaths: ['user'] }
	})
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
