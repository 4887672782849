import { createSlice } from "@reduxjs/toolkit";
import { ExamState } from "./types";
import { fetchExam } from "./thunks";

const initialState = {} as ExamState;

const slice = createSlice({
	name: 'exam',
	initialState,
	reducers: {
		startExam: (state, action) => {
			state.currentExamId = action.payload;
		}
	},
	extraReducers: (builder) => {
		// Login reducers
		builder.addCase(fetchExam.fulfilled, (state, action) => { });
	},
});

export const { startExam } = slice.actions;

export default slice.reducer;
