import React from "react";
import { useAppSelector } from "../../app/hooks";

export const AdminHome = () => {
	const { userId } = useAppSelector(state => state.auth);
	return (
		<div>
			<div>Hello {userId}!</div>
			<div>You are logged in as an admin.</div>
		</div>
	);
};

export default AdminHome;