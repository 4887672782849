import { createSlice } from "@reduxjs/toolkit";
import { performLogin, performLogout } from "./thunks";
import { AuthState, UserRole } from "./types";

const initialState = {
	status: 'PENDING'
} as AuthState;

const slice = createSlice({
	name: 'auth',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// Login reducers
		builder.addCase(performLogin.pending, (state) => {
			state.status = 'PENDING';
			state.userId = undefined;
			state.role = undefined;
		});

		builder.addCase(performLogin.fulfilled, (state, action) => {
			const { userId, role } = action.payload;
			if (userId) {
				state.status = 'LOGGED_IN';
				state.userId = userId;
				state.role = role as UserRole;
			} else {
				state.status = 'GUEST';
				state.userId = undefined;
				state.role = undefined;
			}
		});

		builder.addCase(performLogin.rejected, (state) => {
			state.status = 'GUEST';
			state.userId = undefined;
			state.role = undefined;
		});

		// Logout reducers
		builder.addCase(performLogout.fulfilled, (state) => {
			state.status = 'GUEST';
			state.userId = undefined;
			state.role = undefined;
		});
	},
});

export default slice.reducer;
