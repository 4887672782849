import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

import Button from '@mui/material/Button';

import StudentHome from "../components/student/student";
import AdminHome from "../components/admin/admin";
import ProctorHome from "../components/proctor/proctor";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import { performLogin, performLogout } from "../features/auth/thunks";

import "./home.css";

function viewForRole(role: 'STUDENT' | 'PROCTOR' | 'ADMIN') {
	switch (role!) {
		case 'STUDENT':
			return (
				<StudentHome />
			);
		case 'PROCTOR':
			return (
				<ProctorHome />
			);
		case 'ADMIN':
			return (
				<AdminHome />
			);
	}
}

export const Home = () => {
	const { status, role, userId } = useAppSelector(state => state.auth);

	const dispatch = useAppDispatch();

	const logoutHandler = () => {
		dispatch(performLogout());
	};

	useEffect(() => {
		if (status === 'PENDING') {
			dispatch(performLogin());
		}
	}, [status, dispatch]);

	if (status === 'PENDING') {
		return (
			<div>
				Checking if the user is logged in...
			</div>
		);
	}

	if (status === 'GUEST') {
		return (
			<Navigate to='/login' />
		);
	}

	return (
		<div className="home-page">
			<div className="logged-in-status-bar">
				You are logged in as&nbsp;<b>{userId}</b>&nbsp;({role})&nbsp;
				<Button variant="text" onClick={logoutHandler} size="small">Logout</Button>
			</div>
			{viewForRole(role!)}
		</div>
	);
};

export default Home;