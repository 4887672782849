import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from 'axios';

import { backendAxios } from '../../utils/api';
import { LoginResult } from "./types";

interface LoginRequest {
	userId: string,
	password: string;
}

export interface LoginResponse {
	userId: string,
	role: string,
	status: string,
	message: string;
}

export const performLogin = createAsyncThunk(
	'auth/role',
	async (loginInfo?: LoginRequest): Promise<LoginResult> => {
		const response: AxiosResponse<LoginResponse> = await backendAxios.post<LoginResponse>(
			loginInfo ? 'api/auth/login' : 'api/auth/whoami',
			loginInfo ?? {}
		);
		console.log(response.data);
		return (response.data.status && response.data.status === 'SUCCESS') ? response.data as LoginResult : {};
	}
);

export interface LogoutResponse {
	status: string,
	message: string;
}

export const performLogout = createAsyncThunk(
	'auth/logout',
	async (): Promise<Boolean> => {
		const response: AxiosResponse<LogoutResponse> = await backendAxios.post<LogoutResponse>(
			'api/auth/logout', {}
		);
		return response.data.status === 'SUCCESS';
	}
);