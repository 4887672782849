import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Typography } from "@mui/material";
import { fetchUserData } from "../../features/user/thunks";

type InfoPanelItemProps = {
	type: 'TEXT' | 'URL';
	fieldName?: string;
	fieldValue?: string;
};

export const UserInfoPanelItem = ({ type, fieldName, fieldValue }: InfoPanelItemProps) => {
	if (type === 'TEXT') {
		return (
			<div className="info-panel-item">
				<Typography variant="h6" className="text-key-field">{fieldName}</Typography>
				<div className="text-value-field"> {fieldValue} </div>
			</div>
		);
	}
	return (
		<div className="info-panel-item">
			<Typography variant="h6" className="url-key-field">{fieldName}</Typography>
			<div className="url-value-field"><a href={fieldValue}>{fieldValue}</a></div>
		</div>
	);

};

export const UserInfoPanel = () => {
	const { userId } = useAppSelector(state => state.auth);
	const { studentUser } = useAppSelector(state => state.user);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (userId && !studentUser?.userData) {
			dispatch(fetchUserData(userId));
		}
	});

	if (!studentUser) {
		return (
			<div>No user data to display.</div>
		);
	}

	return (
		<div className="user-info-panel">
			<Typography variant="h4">User Information</Typography>
			<UserInfoPanelItem type='TEXT' fieldName='Name' fieldValue={studentUser.userData?.fullName} />
			<UserInfoPanelItem type='TEXT' fieldName='Date of birth' fieldValue={studentUser.userData?.dateOfBirth} />
			<UserInfoPanelItem type='TEXT' fieldName='Contact number(s)' fieldValue={studentUser.userData?.contactNumbers?.join(", ")} />
			<UserInfoPanelItem type='TEXT' fieldName='Address' fieldValue={studentUser.userData?.personalAddress} />
			<UserInfoPanelItem type='TEXT' fieldName='School' fieldValue={studentUser.userData?.school} />
			<UserInfoPanelItem type='URL' fieldName='Photo Id Link' fieldValue={studentUser.userData?.photoIdUrl} />
		</div>
	);
};
